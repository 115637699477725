import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase/app";
import "firebase/firestore";
import vuetify from "./plugins/vuetify";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

function receiveMessageFromUnity(num:number) {
  // Get element to assign the message
  const lblMessage = document.getElementById("lblMessage");
  // Assign received from Unity message
  console.log(num);
}

require("firebase/auth");

library.add(faFacebook);
library.add(faGoogle);

Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);

const firebaseConfig = {
  /* copied from your Firebase console above as highlighted in red box above */
  apiKey: "AIzaSyDm1FHVzoUPRgkuth8JRsTQ8U4lhvaXRKE",
  authDomain: "pinballgame-9c238.firebaseapp.com",
  projectId: "pinballgame-9c238",
  storageBucket: "pinballgame-9c238.appspot.com",
  messagingSenderId: "664070227349",
  appId: "1:664070227349:web:e30e443385002813eaa29c",
  measurementId: "G-0Q93X98RQZ",
};
firebase.initializeApp(firebaseConfig);
Vue.prototype.$appDB = firebase.firestore();
Vue.prototype.$appAuth = firebase.auth();

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
