






// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Video extends Vue {
  videoElement() {
    return this.$refs.video;
  }
}
