







import { Component, Vue } from "vue-property-decorator";
// import { FirebaseFirestore } from "@firebase/firestore-types";
// import { FirebaseAuth } from "@firebase/auth-types";
import BackgroundVideo from "@/components/BackgroundVideo.vue";
import NProgress from "nprogress";

@Component({
  components: {
    BackgroundVideo,
  },
})
export default class App extends Vue {
  // readonly $appDB!: FirebaseFirestore;
  // readonly $appAuth!: FirebaseAuth;
  // $router: any;
  // // This function is automatically called when
  // // the App component is added to the DOM tree
  // userLoggedIn(): boolean {
  //   return this.$appAuth.currentUser?.uid !== undefined;
  // }
  // doLogout(): void {
  //   this.$appAuth.signOut();
  //   this.$router.push('/'); // Go backward in the "history stack"
  // }
}
